<template>
    <div>
        <Content :paddingBottom="true" :paddingTop="true">
            <PageHeader title="Releases"/>
            <Grid
                v-if="!releases"
                :size="$store.state.settings.smallItems ? 4 : 3"
            >
                <GridItem
                    v-for="i in Array(9).fill(0).map((_, i) => i * i)"
                    :key="i"
                    url="#"
                    type="cover"
                />
            </Grid>
            <Grid
                v-else
                :size="$store.state.settings.smallItems ? 4 : 3"
            >
                <GridItem
                    v-for="release in releases"
                    :key="release.id"
                    :url="`/releases/${release.attributes.url_key}`"
                    :image="$getReleaseImage(release, 'small')"
                    :title_one="release.attributes.title"
                    :title_two="release.attributes.artist"
                    type="cover"
                />
            </Grid>
        </Content>
        <slot name="footer"/>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import Content from '@/components/Content'
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'
    import { releasesQuery } from '@/clients/strapi'

    export default {
        name: 'Releases',

        components: {
            PageHeader,
            Content,
            Grid,
            GridItem
        },

        apollo: {
            releases: {
                query: releasesQuery,
                variables() {
                    return {
                        limit: 999
                    }
                },
                update: data => data.releases.data
            }
        },

        computed: {
            releaseRoutes() {
                if(this.releases){
                    var routes = []
                    this.releases.forEach(function(release){
                        routes.push(release.attributes.url_key)
                    })
                    return routes
                }
                else {
                    return null
                }
            }
        },

        watch: {
            releaseRoutes: {
                handler() {
                    if(this.releaseRoutes) {
                        this.$store.commit('setReleaseRoutes', this.releaseRoutes)
                    }
                }
            }
        },

        metaInfo: {
            title: '● Releases'
        }
    }
</script>
